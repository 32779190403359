@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700);
body {
 font-family:Rubik,sans-serif;
 position:relative;
 font-weight:400;
 font-size:15px
}
ul {
 padding:0;
 margin:0
}
li {
 list-style:none
}
a:focus,a:hover {
 text-decoration:none;
 -webkit-transition:.3s ease;
 -o-transition:.3s ease;
 transition:.3s ease
}
a:focus {
 outline:0
}
img {
 max-width:100%
}
p {
    text-align: justify;
 font-size:16px;
 line-height:30px;
 color:#898b96;
 font-weight:300
}
h4 {
 font-family:Rubik,sans-serif;
 margin:0;
 font-weight:400;
 padding:0;
 color:#363940
}
a {
 color:#777
}
.no-padding {
 padding:0!important
}
.go_top {
 line-height:40px;
 cursor:pointer;
 width:40px;
 background:#5867dd;
 color:#fff;
 position:fixed;
 -webkit-box-shadow:0 4px 4px rgba(0,0,0,.1);
 box-shadow:0 4px 4px rgba(0,0,0,.1);
 -webkit-border-radius:50%;
 border-radius:50%;
 right:-webkit-calc((100% - 1140px)/ 2);
 right:calc((100% - 1140px)/ 2);
 z-index:111;
 bottom:80px;
 text-align:center
}
.go_top span {
 display:inline-block
}
.footer-big {
 padding:91px 0 65px 0
}
.footer-big .footer-widget {
 margin-bottom:40px
}
.footer--light {
    margin-top: 564px;
 background:#e7e8ed
}
.footer-big .footer-menu ul li a,.footer-big p,.footer-big ul li {
 color:#898b96
}
.footer-menu {
 padding-left:48px
}
.footer-menu ul li a {
 font-size:15px;
 line-height:32px;
 -webkit-transition:.3s;
 -o-transition:.3s;
 transition:.3s
}
.footer-menu ul li a:hover {
 color:#5867dd
}
.footer-menu--1 {
 width:100%
}
.footer-widget-title {
 line-height:42px;
 margin-bottom:10px;
 font-size:18px
}
.mini-footer {
    margin-top: -70px;
 background:#192027;
 text-align:center;
 padding:32px 0
}
.mini-footer p {
 margin:0;
 line-height:0px;
 font-size:15px;
 color:#999
}
.mini-footer p a {
 color:#5867dd
}
.mini-footer p a:hover {
 color:#34bfa3
}
.para{
    margin-top: 80px;
}
.cname{

    float: left;
    margin: 6px 0px 0px 13px;
	font-size: 40px;
	font-family: fangsong;
	cursor: pointer;
}

.widget-about img {
    height:70px;
	width:70px;
	float: left;
 display:block;
 margin-bottom:30px
}
.widget-about p {
 font-weight:400
}
.widget-about .contact-details {
 margin:30px 0 0 0
}
.widget-about .contact-details li {
 margin-bottom:10px
}
.widget-about .contact-details li:last-child {
 margin-bottom:0
}
.widget-about .contact-details li span {
 padding-right:12px
}
.widget-about .contact-details li a {
 color:#5867dd
}
@media (max-width:991px) {
 .footer-menu {
  padding-left:0
 }
}
